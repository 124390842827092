import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { layout: 'app' },
    component: Home
  },
  {
    path: '/clientes',
    name: 'clientes',
    meta: { layout: 'app' },
    component: () => import('../views/Clientes.vue')
  },
  {
    path: '/productos',
    name: 'productos',
    meta: { layout: 'app' },
    component: () => import('../views/Productos.vue')
  },
  {
    path: '/proveedores',
    name: 'proveedores',
    meta: { layout: 'app' },
    component: () => import('../views/Proveedores.vue')
  },
  {
    path: '/pedidos/crear/por-detalle',
    name: 'pedidos/crear/por-detalle',
    meta: { layout: 'app' },
    component: () => import(/* webpackChunkName: "pedidos" */ '../views/Pedidos/CrearPorDetalle.vue')
  },
  {
    path: '/pedidos/crear/por-articulo',
    name: 'pedidos/crear/por-articulo',
    meta: { layout: 'app' },
    component: () => import(/* webpackChunkName: "pedidos" */ '../views/Pedidos/CrearPorArticulo.vue')
  },
  {
    path: '/pedidos/detalle/:id',
    name: 'pedidos/detalle',
    meta: { layout: 'app' },
    component: () => import(/* webpackChunkName: "pedidos" */ '../views/Pedidos/Detalle.vue')
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    meta: { layout: 'app' },
    component: () => import(/* webpackChunkName: "pedidos" */ '../views/Pedidos/Listado.vue')
  },
  {
    path: '/ventas',
    name: 'ventas',
    meta: { layout: 'app' },
    component: () => import('../views/Ventas.vue')
  },
  {
    path: '/cobranzas/crear',
    name: 'cobranzas/crear',
    meta: { layout: 'app' },
    component: () => import(/* webpackChunkName: "cobranzas" */  '../views/Cobranzas/Crear.vue')
  },
  {
    path: '/cobranzas/detalle/:id',
    name: 'cobranzas/detalle',
    meta: { layout: 'app' },
    component: () => import(/* webpackChunkName: "cobranzas" */  '../views/Cobranzas/Detalle.vue')
  },
  {
    path: '/cobranzas',
    name: 'cobranzas',
    meta: { layout: 'app' },
    component: () => import(/* webpackChunkName: "cobranzas" */  '../views/Cobranzas/Listado.vue')
  },
  {
    path: '/configuracion',
    name: 'configuracion',
    meta: { layout: 'app' },
    component: () => import('../views/Configuracion.vue')
  },
  {
    path: '/perfil',
    name: 'perfil',
    meta: { layout: 'app' },
    component: () => import(/* webpackChunkName: "account" */ '../views/Perfil.vue')
  },
  {
    path: '/forgot',
    name: 'forgot',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Forgot.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { layout: 'simple' },
    component: () => import(/* webpackChunkName: "auth" */  '../views/Login.vue')
  },
]

const router = new VueRouter({
  mode: process.env.CORDOVA_PLATFORM ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/forgot']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = sessionStorage.getItem('token') // Es válido el token?

  if (authRequired && !loggedIn) {
    return next({
      replace: true,
      name: 'login',
    })
  }

  next()
})

export default router
