<template>
  <v-app>
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      fixed
      app
    >
      <v-list
        dense
        nav
      >
        <v-list-item
          link
          :to="{name: 'clientes'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-box-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'productos'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-view-list</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Productos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="{name: 'proveedores'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Proveedores</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          prepend-icon="mdi-cart"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Pedidos</v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- Deshabilitado
          <v-list-item
            link
            exact
            :to="{name: 'pedidos/crear/por-detalle'}"
          >
            <v-list-item-content>
              <v-list-item-title>
                Crear por Detalle
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          -->

          <v-list-item
            link
            exact
            :to="{name: 'pedidos/crear/por-articulo'}"
          >
            <v-list-item-content>
              <v-list-item-title>
                Crear por Articulo
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            exact
            :to="{name: 'pedidos'}"
          >
            <v-list-item-content>
              <v-list-item-title>
                Listado
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          link
          :to="{name: 'ventas'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-currency-usd</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ventas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          prepend-icon="mdi-credit-card"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Cobranzas</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            link
            exact
            :to="{name: 'cobranzas/crear'}"
          >
            <v-list-item-content>
              <v-list-item-title>
                Crear Cobranza
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            link
            exact
            :to="{name: 'cobranzas'}"
          >
            <v-list-item-content>
              <v-list-item-title>
                Listado
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        
        <v-list-item
          link
          v-if="configParamIsEnabled"
          :to="{name: 'configuracion'}"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-box-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Configuración</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      clipped-left
      color="light-green darken-4"
      dense
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>JQGestión {{ razonSocial }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon to="/perfil">
        <v-icon>mdi-account</v-icon>
      </v-btn>

      <v-btn icon @click="logout()">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>

      <!-- Provides the application the proper gutter -->
      <v-container fill-height fluid class="pa-0">

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
      <span class="footer">&copy; {{ new Date().getFullYear() }} - Desarrollado por <a href="https://jaque.com.ar" target="_blank" rel="nofollow">JAQUE Software</a></span>
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      drawer: null,
    }),
    computed: {
      razonSocial () {
        return this.$store.state.razonSocial
      },

      configParamIsEnabled () {
        let params = new URLSearchParams(document.location.search)
        if (params.get("enable") === "config") {
          return true
        }

        return false
      }
    },
    methods: {
      logout: function () {
        this.$soapClient.call('logout')
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('razonSocial')
        this.$store.state.loggedIn = false
        this.$store.state.razonSocial = ''
        this.$router.push({name: 'login'})
      },
    },
    created: function () {
      this.$eventHub.$on('open-menu', () => this.drawer = true)
    },
    beforeDestroy: function () {
      this.$eventHub.$off('open-menu')
    },
  }
</script>

<style scoped>
  .footer {
    font-size: 14px;
  }
  .footer a {
    color: #33691e;
    text-decoration: none;
    font-weight: bold;
  }
</style>
