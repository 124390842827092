import soap from './soap'
import X2JS from 'x2js'

const soapClient = {}

soapClient.call = function (action, data) {
  data = typeof data === 'undefined' ? {} : data

  const token = sessionStorage.getItem('token')

  const x2js = new X2JS()

  let soapBody = ''

  soapBody+= '<'+action+' xmlns="http://server.jaque">'

  if (token) {
    soapBody+= '<token>'+token+'</token>'
  }

  soapBody+= x2js.js2xml(data)

  soapBody+= '</'+action+'>'

  let xmls =
    '<?xml version="1.0" encoding="utf-8"?>' +
    '<soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">' +
    '<soap:Body>' +
    soapBody +
    '</soap:Body>' +
    '</soap:Envelope>';

  return soap.post('', xmls, {
    headers: {
      'Content-Type': 'text/xml',
      'SOAPAction': 'http://server.jaque/'+action
    }
  })
}

export default soapClient

