import axios from 'axios'
import { eventHub } from './eventHub';

const soap = axios.create({
  baseURL: process.env.VUE_APP_WS_BASE_URL,
  transformResponse: [function (data) {
    let parser = new DOMParser()
    let xmlDoc = parser.parseFromString(data, "text/xml")

    if (xmlDoc.documentElement.nodeName == "soapenv:Envelope") {
      let soapenvBody = xmlDoc.getElementsByTagName('soapenv:Body')[0]
      let actionResponce = soapenvBody.childNodes[0]
      let actionReturn = actionResponce.childNodes[0]
      let soapReturn = actionReturn.textContent

      return JSON.parse(soapReturn)
    }

    return data;
  }],
});

let activeHttpRequests = 0

soap.interceptors.request.use(function (config) {
    activeHttpRequests++;

    return config;
  }, function (error) {
    return Promise.reject(error);
  });

soap.interceptors.response.use(function (response) {
    activeHttpRequests--;

    if (activeHttpRequests === 0) {
      eventHub.$emit('loading-finish')
    }

    if (response.data.error !== null) {
      switch (response.data.error.code) {
        case "401":
        case "402":
        case "403":
          eventHub.$emit('token-invalid', response.data.error.message)
          break
      }

      return Promise.reject(response.data.error);
    }

    return response;
  }, function (error) {
    activeHttpRequests--;

    if (error.response && error.response.status === 401) {
      eventHub.$emit('token-invalid', 'La sesión ha caducado')
    }

    if (activeHttpRequests === 0) {
      eventHub.$emit('loading-finish')
    }

    return Promise.reject(error);
  });

export default soap;
