<template>
  <v-app>
    <component v-bind:is="layout"></component>
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
    >
      {{ snackbarText }}
      <v-btn
        :color="!!snackbarColor ? undefined : 'pink'"
        text
        @click="snackbar = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import AppLayout from './layouts/AppLayout'
import SimpleLayout from './layouts/SimpleLayout'

export default {
  name: 'App',
  data: () => ({
    snackbar: false,
    snackbarText: '',
    snackbarColor: '',
    invalidToken: false,
    invalidTokenReason: ''
  }),
  computed: {
    isLoggedIn () {
      return this.$store.state.loggedIn
    },
    layout () {
      return (this.$route.meta.layout || 'simple') + '-layout'
    }
  },
  created: function () {
    this.$eventHub.$on('token-invalid', (message) => this.revokeToken(message))
    this.$eventHub.$on('loading-finish', () => this.stopLoading())
    this.$eventHub.$on('snackbar-message', (message, color) => this.showSnackbar(message, color))
  },
  beforeDestroy: function () {
    this.$eventHub.$off('token-invalid')
    this.$eventHub.$off('loading-finish')
    this.$eventHub.$off('snackbar-message')
  },
  methods: {
    logout: function () {
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('razonSocial')
      this.$store.state.loggedIn = false
      this.$store.state.razonSocial = ''
      this.$router.push({name: 'login'})
    },
    stopLoading: function () {
      if (this.invalidToken) {
        this.invalidToken = false
        this.showSnackbar(this.invalidTokenReason, 'error')
        this.invalidTokenReason = ''
        this.logout()
      }
    },
    revokeToken: function (message) {
      message = (typeof message === 'undefined') ? 'Error de conexión' : message

      this.invalidToken = true
      this.invalidTokenReason = message
      this.stopLoading()
    },
    showSnackbar: function (message, color) {
      this.snackbarText = message
      this.snackbarColor = color
      this.snackbar = true
    }
  },
  components: {
    'app-layout': AppLayout,
    'simple-layout': SimpleLayout
  }
};
</script>
