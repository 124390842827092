<template>
  <v-layout
    align-center
    justify-center
  >
    <div class="text-center">
      <v-img
        :src="require('../assets/logo.svg')"
        max-width="250"
        class="mb-4 mx-auto"
      ></v-img>

      <v-btn color="primary" large class="d-md-none" @click="openMenu()">
        abrir menu
      </v-btn>
    </div>
  </v-layout>
</template>

<script>

export default {
  name: 'Home',

  methods: {
    openMenu () {
      this.$eventHub.$emit('open-menu')
    },
  },
}
</script>
