import {
  required,
  email,
  confirmed,
  min_value,
  max_value,
  integer
} from "vee-validate/dist/rules";
import { localize, extend } from "vee-validate";
import es from 'vee-validate/dist/locale/es.json';

extend("required", required);
extend("email", email);
extend("confirmed", confirmed);
extend("min_value", min_value);
extend("max_value", max_value);
extend("integer", integer);

localize('es', es);
