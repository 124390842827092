import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import "./plugins/vee-validate";
import vuetify from './plugins/vuetify';
import soapClient from './soapClient'
import { eventHub } from './eventHub';
import './main.css'

Vue.config.productionTip = false

Vue.prototype.$soapClient = soapClient
Vue.prototype.$eventHub = eventHub

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate: function () {
    this.$store.state.loggedIn = !!sessionStorage.getItem('token')
    this.$store.state.razonSocial = sessionStorage.getItem('razonSocial')

    function onDeviceReady() {
      document.addEventListener("backbutton", onBackButton, false)
    }

    function onBackButton(e) {
      e.preventDefault()

      navigator.app.backHistory()
    }

    if (process.env.CORDOVA_PLATFORM) {
      document.addEventListener("deviceready", onDeviceReady, false)
    }
  }
}).$mount('#app')
